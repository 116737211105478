
$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #970000;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/n21-logo-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-top: -0.3rem !important;
                height: 3.5rem !important;
                width: 12rem !important;
                margin-left: -10px;
            }
            .minimal-button .dropdown-toggle {
                margin-top: 3.2px;
            }
            @media (max-width: 991px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                 }
            }
        }
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@import "./bootstrap/scss/bootstrap-flex";
@import "./bootstrap/scss/_animation"; // this import is needed to enable the toggle feature of the detail card
@import "static/styles/lib/colors";

.error-header-wrapper{
    width: 100%;
    text-align: center;
    .error-header-image{
        width: 20vw;
        margin-bottom: -5vw;
        @include media-breakpoint-down(xs) {
            width: 70vw;
        }

		#COLOR {
			fill: $primaryColor;
		}
    }
    .error-header-code{
        font-size: 10vw;
        min-height: .75em;
        margin: 0;
        @include media-breakpoint-down(xs) {
            font-size: 5rem;
        }
        font-weight: bold;
    }
    .error-header-text{
        font-size: 2rem;
        font-weight: bold;
    }
}

.error-details-wrapper{
    text-align: center;
    /* toggles text depending on toggle state */
    #error-toggle-details{
        &[aria-expanded="false"]{
            .show{
                display: initial
            }
            .hide{
                display: none;
            }
        }
        &[aria-expanded="true"]{
            .show{
                display: none
            }
            .hide{
                display: initial;
            }
        }
    }
    #error-details{
        text-align: left;
        max-width: 900px;
        margin: 0 auto;
        .error-description{
            padding: 1rem;
        }
        pre{
            margin: 0;
        }
    }
}
